// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const httpBase = `https://mcatechsolutionapi.net:443/`;

export const environment = {
  production: true,
  apiUrl: httpBase + 'api/',
  apiRefUrl: httpBase + 'api/',
  mcaUploadUrl: httpBase + 'api/mca/upload/',
  websocketUrl: 'wss://08rr723gmi.execute-api.us-east-1.amazonaws.com/fratello',
  fundingVenues: [],
  cognitoConfig: {
    userPoolId: 'us-east-1_TmHJFBZ49',
    userPoolClientId: '1264vh0dd64igt7ugtd4b2f14',
  },
};

export const leadEnv = {
  apiUrl: httpBase + '/api/',
};

export const appTitle = 'MTS';
