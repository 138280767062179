export let generatedServiceWorkerUrl = '';

(() => {
  // Override the real Worker with a stub
  // to return the filename, which will be generated/replaced by the worker-plugin.
  // eslint-disable-next-line no-global-assign
  Worker = class WorkerStub {
    constructor(public stringUrl: string, public options?: WorkerOptions) {}
  } as any;

  const worker = new Worker(new URL('./service.worker.ts', import.meta.url), { type: 'module' });
  generatedServiceWorkerUrl = (worker as any).stringUrl;
})();
