import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MenuService } from './menu.service';
import { MenubarModule } from 'primeng/menubar';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MenubarModule],
})
export class HeaderComponent {
  private menuService = inject(MenuService);

  menuItems = this.menuService.getMenuItems();
}
